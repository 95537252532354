<template>
    <div class="view pa24">
        <div class="d-flex">
            <div>
                <el-input class="w200 mb10 mr10" placeholder="请输入关键字" v-model="searchData.name" />
                <el-button type="primary" @click="searchFun">搜索</el-button>
            </div>
        </div>
        <commonTable :tableData="tableData" :loading="loading" @handleSizeChange="handleSizeChange"
            @handleCurrentChange="handleCurrentChange" :currentPage="currentPage" :total="total">
            <template v-slot:table>
                <el-table-column type="index" align="center" label="序号" />
                <el-table-column prop="companyName" align="center" label="会员名称" show-overflow-tooltip />
                <el-table-column prop="goodPhoto" align="center" label="商品图片">
                    <template slot-scope="scope">
                        <el-image v-if="scope.row.goodPhoto" style="width: 100px; height: 80px;margin-bottom:-10px"
                            :src="scope.row.goodPhoto.split(',')[0]" :preview-src-list="scope.row.goodPhoto.split(',')">
                        </el-image>
                        <p v-else style="width: 100px; height: 80px;line-height: 80px;">
                            暂无图片
                        </p>
                    </template>
                </el-table-column>
                <el-table-column prop="goodsName" align="center" label="商品名称" show-overflow-tooltip />
                <el-table-column prop="price" align="center" label="价格" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{ scope.row.price | getPrice }}起
                    </template>
                </el-table-column>
                <el-table-column prop="stockNum" align="center" label="库存" show-overflow-tooltip />
                <el-table-column align="center" label="操作" width="100">
                    <template slot-scope="scope">
                        <el-button type="text" @click="setMemberPrice(scope.row)">设置会员价</el-button>
                    </template>
                </el-table-column>
            </template>
        </commonTable>
        <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" title="设置会员价" :visible.sync="showModel" :destroy-on-close="true"
             width="600px" center>
            <el-form ref="formData" label-width="78px" label-position="top">
                <div v-for="(row, key) in productSku" :key="key" v-loading="loadingMemberPrice">
                    <el-form-item label="">
                        <div>规格：<el-tag size="small">{{ row.specName }}</el-tag>
                        </div>
                        <div class="ml20" v-for="(child, index) in row.goodSpecModelList" :key="index">
                            <div class="flex-a-b-c mt10 mb10">
                                <div class="flex-a-c">
                                    <div class="mr20">属性：{{ child.specAttribute }}</div>
                                    <div>价格：{{ child.price | getPrice }}</div>
                                </div>
                                <el-button type="primary" @click="addMemberPrice(child.specId)"
                                    v-if="memberPrice[child.specId] && memberLevelLength != memberPrice[child.specId].length">
                                    添加</el-button>
                            </div>
                            <el-alert title="会员等级不能相同。高等级的会员价不能大于等于，低等级的会员价。" class="mb10" style="line-height:16px"
                                type="warning" show-icon>
                            </el-alert>
                            <template v-for="(row, i) in memberPrice[child.specId]">
                                <div class="flex-a-c mt10" v-if="!row.isDel" :key="i">
                                <div class="flex-a-c mr20">会员等级：
                                    <el-select class="w180" v-model="row.levelId" filterable remote placeholder="会员等级">
                                        <el-option v-for="item in memberLevel" :key="item.levelId" :label="item.name"
                                            :value="Number(item.levelId)">
                                        </el-option>
                                    </el-select>
                                </div>
                                <div class="flex-a-c mr10">会员价：
                                    <el-input class="w120 suffixInput" type="number" v-model="row.price"
                                        placeholder="会员价">
                                        <template slot="append">元</template>
                                    </el-input>
                                </div>
                                <el-button type="danger" @click="delMemberPrice(child.specId, i)">删除
                                </el-button>
                            </div>
                            </template>
                        </div>
                        <el-divider content-position="right"></el-divider>
                    </el-form-item>
                </div>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showModel = false">取 消</el-button>
                <el-button type="primary" @click="addUpdate" :loading="addLoading">提 交</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import {
    getGoodsListByLink,
    setAllGoodsMemberPrice, getGoodsMemberPriceList
} from "@/api/association";
import { selectAllMemberLevel } from "@/api/member";
export default {
    name: "groupingTable",
    components: {
        commonTable,
    },
    data() {
        return {
            //列表配置
            currentPage: 1, //当前页
            pageSize: 10, //显示条数
            loading: false, //表格加载
            total: 0, //总条数
            showModel: false,
            tableData: [{ name: "测试" }],
            searchData: { name: "" },
            userInfo: this.$store.state.loginRoot.userInfo,
            associationId: 0,
            //修改/添加会员价设置
            productSku: [],//商品规格数据
            memberLevel: [],//会员等级数据
            prices: {},//商品下的属性价格 （下标为属性ID 值为价格）
            memberLevelAll: {},//等级顺序（等级级别）（下标为等级ID 值为顺序）
            memberPrice: [],//会员价（下标为属性ID 值为会员等级、会员价格、属性ID）
            loadingMemberPrice: false,
            goodsId: 0,
            addLoading: false,
            memberLevelLength: 0,
        };
    },
    filters: {
        getPrice(price) {
            return Number(price / 100).toFixed(2) + "元"
        }
    },
    computed: {
        watchAssociation() {
            return this.$store.state.checkedAssociation
        }
    },
    watch: {
        watchAssociation(row) {
            this.associationId = row.associationId;
            this.currentPage = 1
            this.getList();
        },
    },
    created() {
        this.associationId = JSON.parse(sessionStorage.getItem("checkedAssociation")).associationId;
        this.getList();
        this.selectAllMemberLevel();
    },
    methods: {
        /**@method 获取会员等级 */
        selectAllMemberLevel() {
            return selectAllMemberLevel({
                companyId: this.userInfo.companyId,
                // levelName: name,
                isPage: 0
            }, 9).then(res => {
                if (res.data) {
                    let memberLevel = [];
                    let memberLevelAll = {};
                    for (let row of res.data) {
                        row.name = row.levelSort + "级 " + row.levelName
                        memberLevel.push(row)
                        memberLevelAll[row.levelId] = row.levelSort;
                    }
                    this.memberLevel = memberLevel;
                    this.memberLevelLength = this.memberLevel.length
                    this.memberLevelAll = memberLevelAll;
                }
            })
        },
        /**@method 搜索 */
        searchFun() {
            this.currentPage=1;
            this.getList();
        },
        /**@method 添加会员价 */
        addMemberPrice(specId) {
            let chechkedID = [];
            let levelIds = [];
            for (let row of this.memberPrice[specId]) {//获取全部选中的等级ID转换为数组
                if (row.levelId) {
                    chechkedID.push(row.levelId);
                }
            }
            for (let row of this.memberLevel) {//获取未选中的等级ID
                if (chechkedID.indexOf(row.levelId) === -1) {
                    levelIds.push(row.levelId);
                }
            }
            this.memberPrice[specId].unshift({ specId: specId, price: 0, levelId: levelIds[0] });
            this.$set(this.memberPrice, specId, this.memberPrice[specId]);//让页面监听到数组的变化
        },
        /**@method 删除会员价*/
        delMemberPrice(specId, i) {
            if ("isDel" in this.memberPrice[specId][i]) {
                this.memberPrice[specId][i].isDel=1;
            } else {
                this.memberPrice[specId].splice(i, 1);
            }
           //-console.log(this.memberPrice[specId]);
            this.$set(this.memberPrice, specId, this.memberPrice[specId]);//让页面监听到数组的变化
        },
        /**@method 初始化会员价数组*/
        initMemberPrice(data, memberPrice = []) {
            for (let row of data.goodSpecModelList) {
                for (let child of row.goodSpecModelList) {
                    if (!memberPrice[child.specId]) {
                        if (this.memberLevel.length > 0) {
                            memberPrice[child.specId] = [{ specId: child.specId, price: 0, levelId: this.memberLevel[0].levelId }];
                        }
                    }
                }
            };
            this.memberPrice = memberPrice;
        },
        /**@method 显示设置会员价*/
        setMemberPrice(data) {
            let memberPrice = [];
            this.loadingMemberPrice = true;
            this.productSku = data.goodSpecModelList;
            this.showModel = true;
            this.goodsId = data.goodsId;
            for (let row of this.productSku) {
                for (let child of row.goodSpecModelList) {
                    this.prices[child.specId] = child.price / 100;
                }
            }
            getGoodsMemberPriceList({ goodsId: data.goodsId }).then(res => {
                this.loadingMemberPrice = false;
                if (res.code === 200) {
                    if (res.data) {
                        for (let row of res.data) {
                            if (memberPrice[row.specId]) {
                                memberPrice[row.specId].push({ specId: row.specId, price: row.price / 100, levelId: row.levelId, isDel: 0 })
                            } else {
                                memberPrice[row.specId] = [{ specId: row.specId, price: row.price / 100, levelId: row.levelId, isDel: 0 }]
                            }
                        }
                    }
                }
                this.initMemberPrice(data, memberPrice);
            }).catch(err => {
                this.loadingMemberPrice = false;
                this.initMemberPrice(data);

            })
        },
        addUpdate() {
            //修改添加会员等级
            let priceList = [];
            let openLeveId = true;
            let openPrice = true;
            let sameGrade = false;
            let memberPrice = true;
            let levelData = {};
            let judgeSameGrade = {};
            this.addLoading = true;
            for (let parentSpecId in this.memberPrice) {
                levelData[parentSpecId] = {};
                for (let child of this.memberPrice[parentSpecId]) {
                    let obj={ specId: child.specId, levelId: child.levelId, price: child.price * 100 };
                    if("isDel" in child){//编辑时删除会员价
                        obj.isDel=child.isDel;
                    }
                    priceList.push(obj);
                    if (!child.levelId) {
                        openLeveId = false;
                        break;
                    }
                    levelData[parentSpecId][this.memberLevelAll[child.levelId]] = child.price;
                    if (judgeSameGrade[parentSpecId + "-" + child.levelId] === 0) {
                        sameGrade = true;
                        break;
                    } else {
                        judgeSameGrade[parentSpecId + "-" + child.levelId] = 0;
                    }
                    if (this.prices[child.specId] < Number(child.price)) {
                        memberPrice = false;
                        break;
                    }
                }
                if (sameGrade || !openLeveId || !memberPrice) {
                    break;
                }
            };

            if (priceList.length === 0) {
                this.$message.error("请设置会员价！")
                this.addLoading = false;
                return;
            }
            if (!openLeveId) {
                this.$message.error("请选择会员等级ID！")
                this.addLoading = false;
                return;
            }
            if (sameGrade) {
                this.$message.error("每条属性下的会员等级不能相同！")
                this.addLoading = false;
                return;
            }
            if (!memberPrice) {
                this.$message.error("会员价不能大于商品价格！")
                this.addLoading = false;
                return;
            }
            if (priceList.length > 1) {
                for (let specId in levelData) {
                    if (Object.values(levelData[specId]).length > 0) {
                        for (let level in levelData[specId]) {//循环规格内的商品
                            for (let level2 in levelData[specId]) {
                                if (level < level2) {
                                    //低等级 最大价格
                                    if (levelData[specId][level] <= levelData[specId][level2]) {
                                        openPrice = false;
                                        break;
                                    }
                                }
                            }
                            if (openPrice === false) {
                                break;
                            }
                        }
                        if (openPrice === false) {
                            break;
                        }
                    }
                }
            }
            if (!openPrice) {
                this.$message.error("每个商品属性下的高等级的会员价不能大于等于，低等级的会员价！")
                this.addLoading = false;
                return;
            }
            let params = {
                goodsId: this.goodsId,
                priceList: priceList
            };
            setAllGoodsMemberPrice(params).then(res => {
                this.addLoading = false;
                if (res.code === 200) {
                    this.$message({
                        message: res.data,
                        type: "success",
                    });
                    this.showModel = false;
                } else {
                    this.$message.error(err.message)
                }
            }).catch(err => {
                this.addLoading = false;
                if (err.code === 201) {
                    this.$message.error(err.message)
                }
            })
        },
        /**@method 获取列表 */
        async getList(payload) {
            let params = {
                pageSize: this.pageSize,
                pageNum: this.currentPage,
                accountType: 9,
                goodsName: this.searchData.name,
                associationId: this.associationId,
                showGoodSpec: 1
            };
            try {
                this.loading = true;
                let res = await getGoodsListByLink(params)
                this.loading = false;

                const { data } = res;
                this.tableData = data.pageInfo.list;
                this.total = data.pageInfo.total;
            } catch (error) {
                this.loading = false;
                this.tableData = [];
            }
        },
        /**@method 切换行 */
        handleSizeChange(val) {
            this.pageSize = val;
            this.getList();
        },
        /**@method 切换下一页 */
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getList();
        },
    },
};
</script>

<style lang="scss" scoped>
.switchInput {
    display: flex;

    .selectType {
        width: 150px;
    }
}

.operation-left {
    margin-bottom: 20px;

    .el-button {
        width: 95px;
        height: 32px;
        background: #51cbcd;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;

        &:active {
            border-color: #51cbcd;
        }
    }
}
</style>